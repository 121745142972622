import axios from '@axios'
import common from '@/libs/common'

export default function digitalrecordService() {
  const { throwError, throwSuccess } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`

  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */

  const fetchFileTypes = callback => {
    axios
      .get(`${API_URL}/ExpedientesDigitales/TiposArchivo`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchOperations = (data, isSausage, callback) => {
    const urlSausageOperations = '/ExpedientesDigitales/Operaciones'
    const urlManualOperations = '/ExpedientesDigitales'
    const url = isSausage ? urlSausageOperations : urlManualOperations

    axios
      .get(`${API_URL}${url}`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchOperation = (idExpedienteDigital, isSausage, callback) => {
    const urlSausageOperations = '/ExpedientesDigitales/Operaciones'
    const urlManualOperations = '/ExpedientesDigitales'
    const url = isSausage ? urlSausageOperations : urlManualOperations

    axios
      .get(`${API_URL}${url}/${idExpedienteDigital}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createOperation = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/ExpedientesDigitales`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })

  const updateOperation = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/ExpedientesDigitales`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })

  const deleteOperation = idExpedienteDigital => new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}/ExpedientesDigitales/${idExpedienteDigital}`)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })

  const fetchOperationsDownloadIdExpedienteDigital = (idExpedienteDigital, callback) => {
    axios
      .get(`${API_URL}/ExpedientesDigitales/Operaciones/${idExpedienteDigital}/Descarga`, { responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchOperationsDownloadIdExpedienteDigitalStreaming = (idExpedienteDigital, callback) => {
    axios
      .get(`${API_URL}/ExpedientesDigitales/Operaciones/${idExpedienteDigital}/Descarga/Streaming`, { responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchOperationsDownloadUuid = (Uuid, callback) => {
    axios
      .get(`${API_URL}/ExpedientesDigitales/Operaciones/${Uuid}/Descarga`, { responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchOperationsDownloadUuidStreaming = (Uuid, callback) => {
    axios
      .get(`${API_URL}/ExpedientesDigitales/Operaciones/${Uuid}/Descarga/Streaming`, { responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchOperationsGuid = (Uuid, callback) => {
    axios
      .get(`${API_URL}/ExpedientesDigitales/Operaciones/${Uuid}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchDownloadAllDigitalRecords = (data, callback) => {
    axios
      .get(`${API_URL}/ExpedientesDigitales/Operaciones/Descarga`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchDownloads = callback => {
    axios
      .get(`${API_URL}/ExpedientesDigitales/Descargas`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchSystemSettings = callback => {
    axios
      .get(`${API_URL}/ExpedientesDigitales/Configuraciones/Sistema`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const updateSystemSettings = (data, callback) => {
    axios
      .put(`${API_URL}/ExpedientesDigitales/Configuraciones/Sistema`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchNomenclatureRoots = (data, callback) => {
    axios
      .get(`${API_URL}/ExpedientesDigitales/Configuraciones/NomenclaturasRaiz`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchNomenclatureRoot = ({ idNomenclature }, callback) => {
    axios
      .get(`${API_URL}/ExpedientesDigitales/Configuraciones/NomenclaturasRaiz/${idNomenclature}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const updateNomenclatureRoot = data => {
    axios
      .put(`${API_URL}/ExpedientesDigitales/Configuraciones/NomenclaturasRaiz/${data.idExpedienteDigitalConfiguracionCliente}`, data)
      .then(response => throwSuccess(response))
      .catch(error => throwError(error))
  }

  const createNomenclatureRoot = (data, callback) => {
    axios
      .post(`${API_URL}/ExpedientesDigitales/Configuraciones/NomenclaturasRaiz/${data.claveClienteSysExpert}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchNomenclaturesFiles = (claveClienteSysExpert, callback) => {
    axios
      .get(`${API_URL}/ExpedientesDigitales/Configuraciones/NomenclaturasArchivos/${claveClienteSysExpert}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const updateNomenclaturesFiles = (claveClienteSysExpert, data) => {
    axios
      .put(`${API_URL}/ExpedientesDigitales/Configuraciones/NomenclaturasArchivos/${claveClienteSysExpert}`, data)
      .then(response => throwSuccess(response))
      .catch(error => throwError(error))
  }

  const fetchMetadata = (data, callback) => {
    axios
      .get(`${API_URL}/ExpedientesDigitales/Metadatos`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  return {
    fetchFileTypes,
    fetchOperations,
    fetchOperation,
    createOperation,
    updateOperation,
    deleteOperation,
    fetchOperationsDownloadIdExpedienteDigital,
    fetchOperationsDownloadIdExpedienteDigitalStreaming,
    fetchOperationsDownloadUuid,
    fetchOperationsDownloadUuidStreaming,
    fetchDownloadAllDigitalRecords,
    fetchDownloads,
    fetchSystemSettings,
    updateSystemSettings,
    fetchNomenclatureRoots,
    fetchNomenclatureRoot,
    updateNomenclatureRoot,
    createNomenclatureRoot,
    fetchNomenclaturesFiles,
    updateNomenclaturesFiles,
    fetchMetadata,
    fetchOperationsGuid,
  }
}
