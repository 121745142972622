<template>
  <!-- BODY -->
  <b-card
    no-body
  >
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <!-- Customer-->
            <validation-provider
              #default="{ errors }"
              name="Cliente"
              rules="required"
            >
              <b-form-group
                :label="$t('AppDigitalRecord.list.customer')"
                :state="errors.length > 0 ? false:null"
                label-for="customer"
              >
                <v-select
                  id="customer"
                  v-model="digitalRecordData.claveClienteUnica"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="customers"
                  :reduce="option => option.id"
                  :disabled="!newDigitalRecord"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <!-- Pedimento-->
            <validation-provider
              #default="validationContext"
              name="Numero pedimento"
              rules="numeric|required|min:7|max:7"
            >
              <b-form-group
                :label="$t('AppDigitalRecord.Edit.numperPediment')"
                label-for="numero-pedimento"
              >
                <b-form-input
                  id="numero-pedimento"
                  v-model="digitalRecordData.numeroPedimento"
                  :state="getValidationState(validationContext)"
                  trim
                  type="text"
                  :disabled="!newDigitalRecord"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <!-- Referencia -->
            <validation-provider
              #default="validationContext"
              name="Numero referencia"
              rules="required"
            >
              <b-form-group
                :label="$t('AppDigitalRecord.referenceNumber')"
                label-for="numero-referencia"
              >
                <b-form-input
                  id="numero-referencia"
                  v-model="digitalRecordData.numeroReferencia"
                  :state="getValidationState(validationContext)"
                  trim
                  :disabled="!newDigitalRecord"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <!-- Aduana -->
            <validation-provider
              #default="{ errors }"
              name="Aduana"
              rules="required"
            >
              <b-form-group
                :label="$t('AppDigitalRecord.list.custom')"
                :state="errors.length > 0 ? false:null"
                label-for="aduana"
              >
                <v-select
                  id="aduana"
                  v-model="digitalRecordData.aduana"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="customs"
                  :reduce="option => option.id"
                  :disabled="!newDigitalRecord"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <!-- Patente-->
            <validation-provider
              #default="{ errors }"
              name="Patente"
              rules="required"
            >
              <b-form-group
                :label="$t('AppDigitalRecord.list.patent')"
                :state="errors.length > 0 ? false:null"
                label-for="patente"
              >
                <v-select
                  id="patente"
                  v-model="digitalRecordData.patente"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="patents"
                  :reduce="option => option.id"
                  :disabled="!newDigitalRecord"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <!-- Fecha pago -->
            <validation-provider
              #default="validationContext"
              name="Fecha pago"
              :rules="{ required }"
            >
              <b-form-group
                :label="$t('AppDigitalRecord.list.paymentDate')"
                label-for="payment-date"
              >
                <b-form-datepicker
                  id="payment-date"
                  v-model="digitalRecordData.fechaPago"
                  today-button
                  reset-button
                  close-button
                  :label-today-button="$t('AppDigitalRecord.calendar.labelTodayButton')"
                  :label-reset-button="$t('AppDigitalRecord.calendar.labelResetButton')"
                  :label-close-button="$t('AppDigitalRecord.calendar.labelCloseButton')"
                  :label-prev-year="$t('AppDigitalRecord.calendar.labelPrevYear')"
                  :label-prev-month="$t('AppDigitalRecord.calendar.labelPrevMonth')"
                  :label-current-month="$t('AppDigitalRecord.calendar.labelCurrentMonth')"
                  :label-next-month="$t('AppDigitalRecord.calendar.labelNextMonth')"
                  :label-next-year="$t('AppDigitalRecord.calendar.labelNextYear')"
                  :label-no-date-selected="$t('AppDigitalRecord.calendar.labelNoDateSelected')"
                  :label-help="$t('AppDigitalRecord.calendar.labelHelp')"
                  :placeholder="$t('AppDigitalRecord.noDateSelected')"
                  :state="getValidationState(validationContext)"
                  :disabled="!newDigitalRecord"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <!-- Tipo de operacion-->
            <validation-provider
              #default="{ errors }"
              name="Tipo operacion"
              rules="required"
            >
              <b-form-group
                :label="$t('AppDigitalRecord.list.operationType')"
                :state="errors.length > 0 ? false:null"
                label-for="tipo-operacion"
              >
                <v-select
                  id="tipo-operacion"
                  v-model="digitalRecordData.tipoOperacion"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="operationTypes"
                  :reduce="option => option.id"
                  :disabled="!newDigitalRecord"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <!-- Clave documento-->
            <validation-provider
              #default="{ errors }"
              name="Clave documento"
              rules="required"
            >
              <b-form-group
                :label="$t('AppDigitalRecord.list.documentKey')"
                :state="errors.length > 0 ? false:null"
                label-for="clave-documento"
              >
                <v-select
                  id="clave-documento"
                  v-model="digitalRecordData.claveDocumento"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="documentTypes.map(m => m.texto)"
                  :disabled="!newDigitalRecord"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <!-- Pedimento consolidado -->
            <b-form-group
              :label="$t('AppDigitalRecord.Edit.consolidatedPediment')"
              label-for="pedimento-consolidado"
            >
              <b-form-checkbox
                id="pedimento-consolidado"
                v-model="digitalRecordData.pedimentoConsolidado"
                :disabled="!newDigitalRecord"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <!-- Rectificacion -->
            <b-form-group
              :label="$t('AppDigitalRecord.Edit.rectification')"
              label-for="rectificacion"
            >
              <b-form-checkbox
                id="rectificacion"
                v-model="digitalRecordData.esRectificacion"
                :disabled="!newDigitalRecord"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-card>
          <b-card-header>
            <b-row>
              <b-col
                cols="12"
                md="3"
              >
                <v-select
                  v-model="digitalRecordFileData.idExpedienteDigitalTipoArchivo"
                  :placeholder="$t('AppDigitalRecord.Edit.labelArchive')"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="title"
                  :options="fileTypes"
                  :reduce="option => option.key"
                />
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <file-uploader
                  v-model="digitalRecordFileData.guid"
                  :file-name.sync="digitalRecordFileData.cargaManualNombreOriginalArchivo"
                />
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-input
                  v-model="digitalRecordFileData.cargaManualMetadatoValor1"
                  :placeholder="$t('AppDigitalRecord.Edit.metaData1')"
                  autofocus
                  trim
                />
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-input
                  v-model="digitalRecordFileData.cargaManualMetadatoValor2"
                  :placeholder="$t('AppDigitalRecord.Edit.metaData2')"
                  autofocus
                  trim
                />
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  :disabled="digitalRecordFileData.idExpedienteDigitalTipoArchivo === null || digitalRecordFileData.guid === ''"
                  @click="onAddFile"
                >
                  {{ $t('AppDigitalRecord.Edit.addFile') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-body>
            <b-table
              :items="digitalRecordData.expedienteDigitalArchivos"
              :fields="tableFilesFields"
            >
              <!-- Column: Actions -->
              <template
                v-if="$can('delete', 'ExpedientesDigitales')"
                #cell(actions)="data"
              >
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="onRemoveFile(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{ $t('Lists.Delete') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            {{ digitalRecordData.idExpedienteDigital ? $t('AppDigitalRecord.saveChanges') : $t('AppDigitalRecord.Edit.addExpedient') }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormDatepicker,
  BFormCheckbox,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, min, max } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import i18n from '@/libs/i18n'
import FileUploader from '@/components/FileUploader.vue'
import optionService from '@/services/option.service'
import digitalRecordService from '@/services/digitalrecord.service'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    BTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    FileUploader,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  setup() {
    const id = router.currentRoute.params.idExpedienteDigital
    const newDigitalRecord = !id
    /* Services */
    const {
      fetchCustomers,
      fecthCustoms,
      fetchPatents,
      fetchOperationTypes,
      fetchDocumentoClavesUnicos,
    } = optionService()
    const {
      fetchOperation,
      createOperation,
      fetchFileTypes,
      updateOperation,
    } = digitalRecordService()

    /* Data */
    const blankDigitalRecord = {
      claveClienteUnica: 25,
      numeroPedimento: '',
      numeroReferencia: '',
      aduana: null,
      patente: null,
      fechaPago: null,
      tipoOperacion: null,
      claveDocumento: null,
      pedimentoConsolidado: false,
      esRectificacion: false,
      expedienteDigitalArchivos: [],
    }
    const blankDigitalRecordFile = {
      idExpedienteDigitalTipoArchivo: null,
      nomenclaturaRutaCompletaFinalizado: '-',
      cargaManualNombreOriginalArchivo: '',
      nombre: '-',
      visible: true,
      guid: '',
    }

    const digitalRecordData = ref(JSON.parse(JSON.stringify(blankDigitalRecord)))
    if (id) {
      fetchOperation(id, false, data => {
        digitalRecordData.value = data
      })
    }

    const resetDigitalRecordData = () => {
      digitalRecordData.value = JSON.parse(JSON.stringify(digitalRecordData.value))
    }

    const digitalRecordFileData = ref(JSON.parse(JSON.stringify(blankDigitalRecordFile)))

    const resetDigitalRecordFileData = () => {
      digitalRecordFileData.value = JSON.parse(JSON.stringify(blankDigitalRecordFile))
    }

    const customers = ref([])
    const customs = ref([])
    const patents = ref([])
    const operationTypes = ref([])
    const documentTypes = ref([])
    const fileTypes = ref([])

    fetchCustomers(data => { customers.value = data })
    fecthCustoms(data => { customs.value = data })
    fetchPatents(data => { patents.value = data })
    fetchOperationTypes(data => { operationTypes.value = data })
    fetchDocumentoClavesUnicos(data => { documentTypes.value = data })
    fetchFileTypes(data => { fileTypes.value = data })

    /* Validations */
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetDigitalRecordData)
    /* Methods */
    const onSubmit = () => {
      if (newDigitalRecord) {
        createOperation(digitalRecordData.value)
          .then(() => {
            router.push({ name: 'apps-digtalrecord-management' })
          })
      } else {
        updateOperation(digitalRecordData.value)
          .then(() => {
            router.push({ name: 'apps-digtalrecord-management' })
          })
      }
    }
    const onAddFile = () => {
      digitalRecordFileData.value.nombre = digitalRecordFileData.value.cargaManualNombreOriginalArchivo
      const [fileType] = fileTypes.value.filter(f => f.key.toString() === digitalRecordFileData.value.idExpedienteDigitalTipoArchivo)
      digitalRecordFileData.value.expedienteDigitalTipo = {}
      digitalRecordFileData.value.expedienteDigitalTipo.nombre = fileType.title
      digitalRecordFileData.value.expedienteDigitalTipo.nomenclaturaNombreOrigen = '-'
      digitalRecordData.value.expedienteDigitalArchivos.push(digitalRecordFileData.value)

      resetDigitalRecordFileData()
    }
    const onRemoveFile = item => {
      digitalRecordData.value.expedienteDigitalArchivos = digitalRecordData.value.expedienteDigitalArchivos.filter(f => f.idExpedienteDigitalArchivo !== item.idExpedienteDigitalArchivo)
    }
    /* UI */
    const tableFilesFields = [
      {
        key: 'expedienteDigitalTipo.nombre',
        label: i18n.t('AppDigitalRecord.Edit.typeFile'),
      },
      {
        key: 'nombre',
        label: i18n.t('AppDigitalRecord.Edit.name'),
      },
      {
        key: 'actions',
        label: i18n.t('Lists.Actions'),
      },
    ]

    return {
      // Data
      digitalRecordData,
      digitalRecordFileData,
      customers,
      customs,
      patents,
      operationTypes,
      documentTypes,
      fileTypes,
      resetDigitalRecordFileData,
      // Methods
      onSubmit,
      onAddFile,
      onRemoveFile,
      // Validations
      refFormObserver,
      getValidationState,
      resetForm,
      required,
      min,
      max,
      // UI
      newDigitalRecord,
      tableFilesFields,
    }
  },
}
</script>

<style>

</style>
